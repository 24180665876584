.paris-addresses-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.map-header {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  z-index: 1000;
}

.back-button {
  background-color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  margin-right: 10px;
}

.search-bar {
  flex-grow: 1;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  padding: 5px 15px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  max-width: 300px;
}

.search-icon {
  margin-right: 10px;
  color: #666;
}

.search-bar input {
  border: none;
  background: none;
  outline: none;
  width: 100%;
  font-size: 14px;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.map-section {
  flex: 1;
  position: relative;
  overflow: hidden;
}

.leaflet-container {
  height: 100%;
  width: 100%;
}

.venue-info-container {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
}

.venue-info-box {
  background-color: white;
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0,0,0,0.1);
  flex-grow: 1;
  margin: 0 10px;
  max-height: 200px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.venue-info-box h3 {
  margin-top: 0;
  margin-bottom: 3px;
  font-size: 16px;
  color: #333;
}

.venue-type {
  font-weight: 600;
  color: #666;
  margin-bottom: 5px;
  font-size: 12px;
}

.venue-description {
  margin-bottom: 8px;
  font-size: 12px;
  color: #555;
  line-height: 1.3;
  max-height: 45px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.travel-info {
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 8px;
}

.distance {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  color: #333;
  font-weight: 600;
  font-size: 12px;
}

.distance svg {
  margin-right: 4px;
  color: #4a4a4a;
}

.travel-times {
  display: flex;
  justify-content: space-between;
  font-size: 11px;
}

.travel-mode {
  display: flex;
  align-items: center;
  color: #555;
}

.travel-mode svg {
  margin-right: 3px;
}

.directions-link {
  display: inline-block;
  background-color: #4CAF50;
  color: white;
  text-decoration: none;
  padding: 6px 10px;
  border-radius: 5px;
  font-weight: 600;
  transition: background-color 0.3s;
  font-size: 12px;
  text-align: center;
}

.directions-link:hover {
  background-color: #45a049;
}

.nav-button {
  background-color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  transition: background-color 0.3s;
  align-self: center;
}

.nav-button:hover {
  background-color: #f0f0f0;
}

.venue-list-section {
  height: 30vh;
  overflow-y: auto;
  padding: 10px;
  background-color: white;
}

.venue-list-section ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.venue-list-section li {
  padding: 15px;
  cursor: pointer;
  transition: background-color 0.2s;
  border-bottom: 1px solid #e0e0e0;
}

.venue-list-section li:last-child {
  border-bottom: none;
}

.venue-list-section li:hover {
  background-color: #f5f5f5;
}

.venue-list-section li.selected {
  background-color: #e6f7ff;
  border-left: 4px solid #1890ff;
}

.venue-list-section li strong {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
  color: #333;
}

.venue-list-section li p {
  margin: 0 0 5px 0;
  font-size: 14px;
  color: #666;
}

.list-travel-times {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #888;
}

.list-travel-times span {
  display: flex;
  align-items: center;
}

.list-travel-times span svg {
  margin-right: 3px;
}

/* Custom marker styles */
.custom-marker-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.3);
}

.marker-restaurant {
  background-color: #FF5733;
}

.marker-bar {
  background-color: #33FF57;
}

.marker-cafe {
  background-color: #3357FF;
}

.marker-museum {
  background-color: #FF33F1;
}

.marker-landmark {
  background-color: #33FFF1;
}

.marker-default {
  background-color: #FFA500;
}